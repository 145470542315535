



























































































































































































































































































































.el-switch.is-checked .el-switch__core {
  background: var(--schema-color);
  border: 1px solid var(--schema-color);
}
